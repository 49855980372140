import { useEffect, useState } from "react";
import { Container, Input, Popup, Table, TextArea } from "semantic-ui-react";
import { IQuestion, ISubQuestion } from "../../../models/question";
import { ESubValueType, ISubValue, IValue } from "../../../models/value";
import { nullOrUndefined } from "../../../helpers/commonHelper";
import generateGuid from "../../../helpers/guid";
import { toast } from "../../..";
import { GroupLegend } from "./HelperComponents/GroupLegend";
import { isNaN } from "lodash";
import {
    displayFirstRowCell,
    displayTableTopLeft,
    fullSplit,
    sanitizeFloatText,
} from "./HelperComponents/TableDisplayHelper";
import "./TableInput.css";

export interface ITableInputDisplayProps {
    question: IQuestion;
    value: IValue;
    lastYearValue?: IValue;
    lastYearQuestion?: IQuestion;
    saveValues: (subValues: ISubValue[]) => void;
    errors?: [string, string, string][];
    specialErrors?: [IQuestion, ISubQuestion, ISubQuestion, string, number][];
    disabled?: boolean;
}

export const TableInputDisplay = ({
    question,
    value,
    lastYearValue,
    lastYearQuestion,
    saveValues,
    errors,
    specialErrors,
    disabled,
}: ITableInputDisplayProps) => {
    const [subValues, setSubValues] = useState<ISubValue[]>([]);

    // Sub-values delta will contain the structure of sub-values (meaning reference to sub-questions from the "question" object etc.)
    // but the values themselves will be calculated as the difference between the current value and the last year's value on numeric sub-questions.
    const [subValuesDelta, setSubValuesDelta] = useState<ISubValue[]>([]);

    useEffect(() => {
        if (value.subValues) {
            setSubValues(value.subValues);
        }
    }, [value, setSubValues]);

    useEffect(() => {
        if (!lastYearQuestion || !lastYearValue) return;

        const orderedRows = question.subQuestions?.filter(sq => sq.isPrimaryDimension);
        const orderedCols = question.subQuestions?.filter(sq => !sq.isPrimaryDimension);

        orderedRows?.sort((f, s) => f.dimensionOrderNumber - s.dimensionOrderNumber);
        orderedCols?.sort((f, s) => f.dimensionOrderNumber - s.dimensionOrderNumber);

        const orderedLastYearRows = lastYearQuestion?.subQuestions?.filter(
            sq => sq.isPrimaryDimension
        );
        const orderedLastYearCols = lastYearQuestion?.subQuestions?.filter(
            sq => !sq.isPrimaryDimension
        );

        orderedLastYearRows?.sort((f, s) => f.dimensionOrderNumber - s.dimensionOrderNumber);
        orderedLastYearCols?.sort((f, s) => f.dimensionOrderNumber - s.dimensionOrderNumber);

        let newSubValuesDelta: ISubValue[] =
            value.subValues?.map(sv => {
                return { ...sv };
            }) ?? [];
        newSubValuesDelta.forEach(sv => {
            // Find the corresponding sub-value in the last year's value. We do matching based on the order of sub-questions, because questions from past years do not share the same IDs.
            const rowOrder = orderedRows?.findIndex(sq => sq.id === sv.primaryDimensionSubQuestion);
            const colOrder = orderedCols?.findIndex(
                sq => sq.id === sv.secondaryDimensionSubQuestion
            );

            const previousValue = lastYearValue.subValues?.find(
                sv =>
                    orderedLastYearRows?.findIndex(
                        sq => sq.id === sv.primaryDimensionSubQuestion
                    ) === rowOrder &&
                    orderedLastYearCols?.findIndex(
                        sq => sq.id === sv.secondaryDimensionSubQuestion
                    ) === colOrder
            );

            if (previousValue) {
                if (!nullOrUndefined(sv.numeric) && !nullOrUndefined(previousValue.numeric)) {
                    sv.numeric = parseFloat((sv.numeric! - previousValue.numeric!).toFixed(4));
                }
            }
        });

        setSubValuesDelta(newSubValuesDelta);
    }, [
        value.subValues,
        lastYearValue?.subValues,
        lastYearQuestion,
        lastYearValue,
        question.subQuestions,
    ]);

    const updateSubValue = (
        primarySubQuestion: ISubQuestion,
        secondarySubQuestion: ISubQuestion,
        newValue: string | number,
        newValueType: ESubValueType
    ) => {
        let newSubValues = [...subValues];

        let subValueIndex: number = subValues.findIndex(
            sv =>
                sv.primaryDimensionSubQuestion === primarySubQuestion.id &&
                sv.secondaryDimensionSubQuestion === secondarySubQuestion.id
        );

        let subValue: ISubValue =
            subValueIndex !== -1
                ? newSubValues[subValueIndex]
                : {
                      id: generateGuid(),
                      primaryDimensionSubQuestion: primarySubQuestion.id,
                      secondaryDimensionSubQuestion: secondarySubQuestion.id,
                      valueId: value.id,
                  };

        switch (newValueType) {
            case ESubValueType.Text:
                subValue.textValue = newValue as string;
                break;
            case ESubValueType.Numeric:
                subValue.numeric = newValue as number;
                break;
        }

        // If updated content is empty string, null or undefined, remove this subvalue from the array.
        if (
            !subValue.textValue &&
            (subValue.numeric === undefined || subValue.numeric === null) &&
            subValueIndex !== -1
        ) {
            newSubValues.splice(subValueIndex, 1);
        } else {
            if (subValueIndex !== -1) {
                newSubValues[subValueIndex] = subValue;
            } else {
                newSubValues.push(subValue);
            }
        }

        setSubValues(newSubValues);
    };

    return (
        <Container className="table-input-display">
            <GroupLegend question={question} />
            <div className="table-wrapper">
                {lastYearQuestion && lastYearValue && (
                    <span className="locked previous-table">
                        <h3>PREVIOUS ANSWERS:</h3>
                        <TableDisplay
                            disabled
                            question={lastYearQuestion}
                            value={lastYearValue}
                            subValues={lastYearValue.subValues ?? []}
                        />
                    </span>
                )}

                <span>
                    {lastYearQuestion && lastYearValue && <h3>CURRENT ANSWERS: </h3>}
                    <TableDisplay
                        question={question}
                        disabled={disabled}
                        value={value}
                        subValues={subValues}
                        updateSubValue={updateSubValue}
                        setSubValues={setSubValues}
                        saveValues={saveValues}
                        errors={errors}
                    />
                </span>

                {lastYearQuestion && lastYearValue && (
                    <span className="locked delta-table">
                        <h3>DELTA (CURRENT - PREVIOUS) UP TO 4 DIGITS: </h3>
                        <TableDisplay
                            disabled
                            question={question}
                            value={value}
                            subValues={subValuesDelta}
                            specialErrors={specialErrors}
                        />
                    </span>
                )}
            </div>
        </Container>
    );
};

interface ITableDisplayProps {
    question: IQuestion;
    disabled?: boolean;
    value: IValue;
    subValues: ISubValue[];
    updateSubValue?: (
        primarySubQuestion: ISubQuestion,
        secondarySubQuestion: ISubQuestion,
        newValue: string | number,
        newValueType: ESubValueType
    ) => void;
    setSubValues?: (subValues: ISubValue[]) => void;
    saveValues?: (subValues: ISubValue[]) => void;
    errors?: [string, string, string][];
    specialErrors?: [IQuestion, ISubQuestion, ISubQuestion, string, number][];
}

export const TableDisplay = ({
    question,
    disabled,
    value,
    subValues,
    updateSubValue,
    setSubValues,
    saveValues,
    errors,
    specialErrors,
}: ITableDisplayProps) => {
    const [orderedRows, setOrderedRows] = useState<ISubQuestion[]>([]);
    const [orderedCols, setOrderedCols] = useState<ISubQuestion[]>([]);

    useEffect(() => {
        if (question.subQuestions) {
            let rows = question.subQuestions.filter(sq => sq.isPrimaryDimension);
            let cols = question.subQuestions.filter(sq => !sq.isPrimaryDimension);

            // Just in case backend returns unsorted sub questions.
            rows.sort((f, s) => f.dimensionOrderNumber - s.dimensionOrderNumber);
            cols.sort((f, s) => f.dimensionOrderNumber - s.dimensionOrderNumber);

            setOrderedRows(rows);
            setOrderedCols(cols);
        }
    }, [question, setOrderedRows, setOrderedCols]);

    const parsePastedContent = (data: string, primId: string, secId: string) => {
        if (!setSubValues) return;

        let primary = [...(question.subQuestions?.filter(sq => sq.isPrimaryDimension) ?? [])];
        let secondary = [...(question.subQuestions?.filter(sq => !sq.isPrimaryDimension) ?? [])];
        let newSubValues = [...subValues];

        primary.sort((f, s) => f.dimensionOrderNumber - s.dimensionOrderNumber);
        secondary.sort((f, s) => f.dimensionOrderNumber - s.dimensionOrderNumber);

        let primIndex = primary.findIndex(sq => sq.id === primId);
        let secIndex = secondary.findIndex(sq => sq.id === secId);

        // Spliting data.value into 2D array. \n splits by first dimension (row) and \t splits by second.
        // Note: if text content contains \n or \t inside cell as a legitimate
        // value, then those sections are wrapped in quotes.
        let splitContent: string[][] | null = fullSplit(data);
        if (!splitContent) {
            return;
        }

        // First check if number of rows are within limit.
        if (primIndex + splitContent.length > primary.length) {
            toast(
                `Pasting action found ${splitContent.length} rows, but only ${
                    primary.length - primIndex
                } rows are available from current cell.`,
                false,
                5000
            );
            return;
        }

        // Then for each row check if we have enough column space.
        const availableColSpaces = secondary.length - secIndex;
        for (let i = 0; i < splitContent.length; i++) {
            if (splitContent[i].length > availableColSpaces) {
                toast(
                    `Pasting action found ${splitContent[i].length} columns in row [${
                        i + 1
                    }] but only ${availableColSpaces} columns are available from current cell`,
                    false,
                    5000
                );

                return;
            }
        }

        let currentRow = primIndex;
        splitContent.forEach(row => {
            let currentCol = secIndex;

            row.forEach(cell => {
                let targetSubValueIndex: number = subValues.findIndex(
                    sv =>
                        sv.primaryDimensionSubQuestion === primary[currentRow].id &&
                        sv.secondaryDimensionSubQuestion === secondary[currentCol].id
                );

                let targetSubValue: ISubValue =
                    targetSubValueIndex !== -1
                        ? newSubValues[targetSubValueIndex]
                        : {
                              id: generateGuid(),
                              primaryDimensionSubQuestion: primary[currentRow].id,
                              secondaryDimensionSubQuestion: secondary[currentCol].id,
                              valueId: value.id,
                          };

                if (secondary[currentCol].subQuestionType === "Text") {
                    targetSubValue.textValue = cell;
                } else if (secondary[currentCol].subQuestionType === "Numeric") {
                    let parsed = parseFloat(sanitizeFloatText(cell));
                    targetSubValue.numeric = parsed;
                }

                if (targetSubValueIndex === -1) {
                    newSubValues.push(targetSubValue);
                }

                currentCol++;
            });

            currentRow++;
        });

        setSubValues(newSubValues);
    };

    const findSubValue = (primDimId: string, secDimId: string, type: "number" | "text") => {
        let subValue = subValues.find(
            sv =>
                sv.primaryDimensionSubQuestion === primDimId &&
                sv.secondaryDimensionSubQuestion === secDimId
        );

        if (subValue) {
            if (type === "text") {
                return subValue.textValue ?? "";
            }
            if (type === "number") {
                return subValue.numeric !== undefined &&
                    subValue.numeric !== null &&
                    !isNaN(subValue.numeric)
                    ? subValue.numeric
                    : "";
            }
        }

        return "";
    };

    return (
        <Table celled compact size="small" collapsing>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{displayTableTopLeft(question.text)}</Table.HeaderCell>
                    {orderedCols.map(sq => (
                        <Table.HeaderCell
                            key={sq.id}
                            style={{
                                backgroundColor: question.groups?.find(g => g.id === sq.groupId)
                                    ?.backgroundColorHex,
                            }}
                        >
                            <span
                                style={{
                                    color: question.groups?.find(g => g.id === sq.groupId)
                                        ?.textColorHex,
                                }}
                            >
                                {sq.isRequired && <span className="alert">* </span>}
                                {sq.dimensionText}
                            </span>
                        </Table.HeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {orderedRows.map(sq => (
                    <Table.Row key={sq.id}>
                        {/** First cell in each row just displays the subquestion text. */}
                        <Table.Cell
                            style={{
                                backgroundColor: question.groups?.find(g => g.id === sq.groupId)
                                    ?.backgroundColorHex,
                            }}
                            className="row-display-cell"
                        >
                            <Popup
                                content={sq.dimensionText}
                                trigger={
                                    <span
                                        style={{
                                            color: question.groups?.find(g => g.id === sq.groupId)
                                                ?.textColorHex,
                                        }}
                                    >
                                        {sq.isRequired && <span className="alert">* </span>}
                                        {displayFirstRowCell(question.text, sq.dimensionText)}
                                    </span>
                                }
                            />
                        </Table.Cell>
                        {/** After that display as many cells as there are defined columns. */}
                        {orderedCols.map(col => {
                            let subValue = findSubValue(sq.id, col.id, "number");
                            let specialError = specialErrors?.find(
                                e => e[1].id === sq.id && e[2].id === col.id && subValue === e[4]
                            );

                            return (
                                <Table.Cell
                                    key={sq.id + col.id}
                                    className={`input-cell ${
                                        errors?.find(e => e[1] === sq.id && e[2] === col.id)
                                            ? "validation-error"
                                            : ""
                                    } ${specialError ? "special-error" : ""}`}
                                >
                                    <Popup
                                        content={specialError?.[3]}
                                        disabled={!specialError}
                                        trigger={<span className="popup-trigger" />}
                                    />
                                    <div className="cell-content-container">
                                        {col.subQuestionType === "Text" && (
                                            <TextArea
                                                rows={2}
                                                readOnly={disabled}
                                                value={findSubValue(sq.id, col.id, "text") ?? ""}
                                                onChange={(e: any, data: any) => {
                                                    if (!updateSubValue) return;

                                                    // This is how we differentiate between copy/paste change and classic typing change event.
                                                    if (
                                                        (e.nativeEvent as InputEvent).inputType ===
                                                        "insertFromPaste"
                                                    ) {
                                                        parsePastedContent(
                                                            data.value,
                                                            sq.id,
                                                            col.id
                                                        );
                                                    } else {
                                                        updateSubValue(
                                                            sq,
                                                            col,
                                                            data.value,
                                                            ESubValueType.Text
                                                        );
                                                    }
                                                }}
                                                onBlur={() => saveValues?.(subValues)}
                                            />
                                        )}
                                        {col.subQuestionType === "Numeric" && (
                                            <Input
                                                readOnly={disabled}
                                                type="number"
                                                value={findSubValue(sq.id, col.id, "number")}
                                                onChange={e => {
                                                    if (!updateSubValue) return;

                                                    if (
                                                        (e.nativeEvent as InputEvent).inputType !==
                                                        "insertFromPaste"
                                                    ) {
                                                        updateSubValue(
                                                            sq,
                                                            col,
                                                            e.target.valueAsNumber,
                                                            ESubValueType.Numeric
                                                        );
                                                    }
                                                }}
                                                onPaste={(e: any) => {
                                                    parsePastedContent(
                                                        e.clipboardData.getData("Text"),
                                                        sq.id,
                                                        col.id
                                                    );
                                                }}
                                                onBlur={() => saveValues?.(subValues)}
                                            />
                                        )}
                                    </div>
                                </Table.Cell>
                            );
                        })}
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
};
