import { Link } from "react-router-dom";
import { Dropdown, Icon, Menu } from "semantic-ui-react";
import Logo from "../../images/entsoe_logo.svg";
import "./Header.css";

export const PublicHeader = () => {
    return (
        <Menu secondary>
            <Menu.Item as={Link} to="/public" header>
                <div id="logo-container">
                    <img src={Logo} alt="Logo" />
                    <p id="imp-logo-text">Implementation Monitoring Platform</p>
                </div>
            </Menu.Item>

            <Menu.Item as={Link} to="/public/form-overview" content="Forms" />
            <Menu.Item as={Link} to="/public/reports" content="Reports" />

            <Dropdown item text="Dynamic graphs" icon="chevron down">
                <Dropdown.Menu>
                    <Menu.Item
                        as={Link}
                        to="/public/reports/data-view/generation-capacity"
                        content="Generation and HVDC capacities"
                    />
                    <Menu.Item
                        as={Link}
                        to="/public/reports/data-view/frt"
                        content="FRT non-exhaustive parameters"
                    />
                    <Menu.Item
                        as={Link}
                        to="/public/reports/data-view/frt-additional"
                        content="Other non-exhaustive parameters"
                    />
                </Dropdown.Menu>
            </Dropdown>

            <Menu.Item
                as={Link}
                to="/public/reports/non-exhaustive-parameters"
                content="Non-exhaustive parameters"
            />

            <Menu.Item as={Link} to="/login" position="right">
                <span>Login</span>
                <Icon name="user circle" size="large" />
            </Menu.Item>
        </Menu>
    );
};
