import { useCallback, useEffect, useState } from "react";
import { Checkbox, Loader, Segment, Select, Table } from "semantic-ui-react";
import { toast } from "../..";
import { yearsProps } from "../../models/common";
import { IReport } from "../../models/report";
import { changePublicVisibility, getPublishedReports } from "../../services/reportsService";

export const ReportsSection = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [publishedReports, setPublishedReports] = useState<IReport[]>([]);
    const [selectedYear, setSelectedYear] = useState<number>();
    const fetchPublishedReports = useCallback(async () => {
        try {
            setLoading(true);
            const res = await getPublishedReports(selectedYear);
            setPublishedReports(res);
            setLoading(false);
        } catch (err) {
            toast("Unable to fetch reports", false, 3000);
        }
    }, [selectedYear]);

    useEffect(() => {
        fetchPublishedReports();
    }, [fetchPublishedReports]);

    const updatePublicVisibilityForReport = async (guid: string, isPublic: boolean) => {
        try {
            const res = await changePublicVisibility(guid, isPublic);

            let newReports = [...publishedReports];
            for (let i = 0; i < newReports.length; i++) {
                if (newReports[i].id === res.id) {
                    newReports[i].isPublic = res.isPublic;
                }
            }
            setPublishedReports(newReports);
            toast("External publication set successfully", true, 3000);
        } catch (err) {
            toast("Unable to edit visibility", false, 3000);
        }
    };

    return (
        <div className="reports-container section-container">
            <div className="title-container">
                <h2>Reports:</h2>
                <Select
                    clearable
                    value={selectedYear}
                    options={yearsProps}
                    onChange={(e, data) => setSelectedYear(data.value as number)}
                    selectOnBlur={false}
                />
            </div>
            {loading ? (
                <Loader active={loading} inline="centered" />
            ) : (
                <Segment>
                    <Table basic="very" compact="very" fixed>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Title</Table.HeaderCell>
                                <Table.HeaderCell>Version</Table.HeaderCell>
                                <Table.HeaderCell>From</Table.HeaderCell>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell>External publication</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {publishedReports.map(r => {
                                return (
                                    <Table.Row key={r.id}>
                                        <Table.Cell>{r.title}</Table.Cell>
                                        <Table.Cell>{r.version}</Table.Cell>
                                        <Table.Cell>{r.from}</Table.Cell>
                                        <Table.Cell>
                                            {new Date(r.date).toLocaleString("hr-HR", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "numeric",
                                            })}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Checkbox
                                                toggle
                                                checked={r.isPublic}
                                                onChange={(e, data) =>
                                                    updatePublicVisibilityForReport(
                                                        r.id!,
                                                        data.checked as boolean
                                                    )
                                                }
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            )}
        </div>
    );
};
