import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { UserData, UserRole } from "../../actions/authentificationActions";
import {
    Button,
    Table,
    Icon,
    Select,
    DropdownItemProps,
    Input,
    Loader,
    Popup,
    Modal,
} from "semantic-ui-react";
import { AppState } from "../../store/configureStore";
import { useSelector } from "react-redux";
import { IResultsFilter, resultsFilterInit } from "../../models/resultsFilter";
import { IFormResultRow } from "../../models/formResultRow";
import {
    getFilteredResults,
    getFilters,
    downloadXls,
    downloadPdfFile,
    getPublicResults,
} from "../../services/resultsService";
import {
    displayVisibilityOption,
    EVisibility,
    initNewForm,
    visibilityOptions,
} from "../../models/form";
import { CreateAndEditForm } from "../../containers/FormEditor/CreateAndEditForm";
import { editForm, unpublishForm } from "../../services/formsService";
import staticFilterOptions from "./staticFilterOptions.json";
import { toast } from "../..";
import { Legend } from "../Legend/Legend";
import { ExportModal } from "../ExportModal/ExportModal";
import { ReactComponent as EuropeIcon } from "./Europe_icon.svg";
import { ImpLoading } from "../ImpLoading/ImpLoading";
import { yearsProps } from "../../models/common";
import "./FormsOverviewTable.css";

interface ITableProps {
    overview?: boolean;
    refreshTrigger?: () => void;
}

const COUNTRY_FILTER_KEY = "formFilterCountry";

export const FormsOverviewTable = ({ overview, refreshTrigger }: ITableProps) => {
    const user: UserData = useSelector((state: AppState) => state.user);
    const history = useHistory();

    const [resultsFilter, setResultsFilter] = useState<IResultsFilter>();
    const [filterData, setFilterData] = useState(resultsFilterInit);

    const [tableData, setTableData] = useState<IFormResultRow[]>([]);
    const [tableDataLoading, setTableDataLoading] = useState(false);

    const [activeForm, setActiveForm] = useState(initNewForm);
    const [editOpen, setEditOpen] = useState<boolean>(false);

    const [activeXlsForm, setActiveXlsForm] = useState<string>();
    const [activeResultId, setActiveResultId] = useState<string>();

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");

    // FormId if unpublish modal is open, undefined if closed.
    const [unpublishModalForm, setUnpublishModalForm] = useState<string>();

    const refreshFilters = useCallback(async () => {
        try {
            let res: IResultsFilter = await getFilters(filterData.category, filterData.block);
            setResultsFilter(res);

            if (res.countries?.length > 0) {
                if (user.role === UserRole.Admin) {
                    if (
                        !filterData.country ||
                        !res.countries.find(c => c.id === filterData.country)
                    ) {
                        setFilterData(prevFilter => ({
                            ...prevFilter,
                            country:
                                sessionStorage.getItem(COUNTRY_FILTER_KEY) ?? res.countries[0].id,
                            nclpMode: undefined,
                        }));
                        sessionStorage.removeItem(COUNTRY_FILTER_KEY);
                    }
                } else if (user.role === UserRole.NCLP || user.role === UserRole.Viewer) {
                    if (!filterData.country) {
                        setFilterData(prevFilter => ({
                            ...prevFilter,
                            country:
                                sessionStorage.getItem(COUNTRY_FILTER_KEY) ??
                                user.countryGuid ??
                                res.countries[0].id,
                            nclpMode: user.actualRole === UserRole.Admin ? true : undefined,
                        }));
                    }
                } else {
                    if (!filterData.country) {
                        setFilterData(prevFilter => ({
                            ...prevFilter,
                            country:
                                sessionStorage.getItem(COUNTRY_FILTER_KEY) ?? res.countries[0].id,
                        }));
                    }
                }
            }
        } catch {
            toast("Unable to load filter options.", false, 1000);
        }
    }, [
        filterData.category,
        filterData.block,
        filterData.country,
        user.role,
        user.countryGuid,
        user.actualRole,
    ]);

    useEffect(() => {
        refreshFilters();
    }, [refreshFilters]);

    const refreshContent = useCallback(async () => {
        if (user.guid) {
            if (filterData.country) {
                const res = await getFilteredResults(filterData);
                setTableData(res);
            }
        } else {
            if (filterData.country) {
                const res = await getPublicResults(filterData);
                setTableData(res);
            }
        }
    }, [filterData, user]);

    useEffect(() => {
        if (filterData.name === "") {
            setTableDataLoading(true);
            refreshContent().finally(() => {
                setTableDataLoading(false);
            });
        }
    }, [refreshContent, filterData]);

    useEffect(() => {
        refreshContent();
    }, [refreshContent, filterData.name]);

    const openForm = (element: IFormResultRow) => {
        if (!overview) {
            sessionStorage.setItem(COUNTRY_FILTER_KEY, filterData.country);
            history.push(`/forms/view/${element.formId}/results/${element.resultId}`);
        }
    };

    const editFormProcedure = async () => {
        if (activeForm !== undefined && activeForm.id !== undefined) {
            try {
                const res = await editForm(activeForm.id, activeForm);
                if (res.id) {
                    setActiveForm(initNewForm);

                    getFilteredResults(filterData).then(r => {
                        setTableData(r);
                    });
                    toast("Changes saved successfully", true, 1000);
                    setEditOpen(false);
                }
            } catch (error) {
                toast("Error while editing form", false, 1000);
            }
        }
    };

    const downloadExcel = useCallback(
        (sectionId: string[], specialAcerDataFormat: boolean) => {
            if (activeXlsForm) {
                setLoadingMessage("Generating Excel file.");
                setLoading(true);
                downloadXls(activeXlsForm, sectionId, specialAcerDataFormat).then(() => {
                    setLoading(false);
                });
                setActiveXlsForm(undefined);
                setActiveResultId(undefined);
            }
        },
        [activeXlsForm]
    );

    const downloadPdf = useCallback((resultId: string) => {
        setLoadingMessage("Generating PDF file. This may take a while.");
        setLoading(true);
        downloadPdfFile(resultId)
            .catch(() => {
                toast("Error while generating PDF file", false, 3000);
            })
            .finally(() => {
                setLoading(false);
                setActiveXlsForm(undefined);
                setActiveResultId(undefined);
            });
    }, []);

    const handleUnpublishForm = useCallback(async () => {
        if (!unpublishModalForm) return;

        unpublishForm(unpublishModalForm)
            .then(() => {
                setUnpublishModalForm(undefined);
                refreshContent();
                refreshTrigger?.();
                toast("Form unpublished successfully", true, 3000);
            })
            .catch(err => {
                if (err.message) toast(err.message, false, 3000);
                else toast("Error while unpublishing form", false, 3000);
            });
    }, [unpublishModalForm, refreshContent, refreshTrigger]);

    return (
        <>
            <ImpLoading show={loading} message={loadingMessage} />
            {!overview && <Legend new partial submitted closedNotSubmitted />}
            <Table fixed className="forms-overview" columns={overview ? 7 : 9}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <Input
                                fluid
                                value={filterData.name}
                                onChange={(e, data) =>
                                    setFilterData({ ...filterData, name: data.value })
                                }
                                icon="search"
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <Select
                                clearable
                                selectOnBlur={false}
                                className="filter-selection"
                                options={yearsProps}
                                value={filterData.year}
                                onChange={(e, data) =>
                                    setFilterData({ ...filterData, year: data.value as string })
                                }
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <Select
                                clearable
                                selectOnBlur={false}
                                className="filter-selection"
                                options={
                                    resultsFilter?.categories.map<DropdownItemProps>(c => {
                                        return { key: c.id, value: c.id, text: c.name };
                                    }) || []
                                }
                                value={filterData.category}
                                onChange={(e, data) =>
                                    setFilterData({ ...filterData, category: data.value as string })
                                }
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <Select
                                clearable
                                selectOnBlur={false}
                                className="filter-selection"
                                options={staticFilterOptions.submission}
                                value={filterData.submission}
                                onChange={(e, data) =>
                                    setFilterData({
                                        ...filterData,
                                        submission: data.value as string,
                                    })
                                }
                            />
                        </Table.HeaderCell>
                        {!overview && (
                            <Table.HeaderCell>
                                <Select
                                    clearable
                                    selectOnBlur={false}
                                    className="filter-selection"
                                    options={staticFilterOptions.status}
                                    value={filterData.status}
                                    onChange={(e, data) =>
                                        setFilterData({
                                            ...filterData,
                                            status: data.value as string,
                                        })
                                    }
                                />
                            </Table.HeaderCell>
                        )}
                        {user.role === UserRole.Admin && (
                            <Table.HeaderCell>
                                <Select
                                    clearable
                                    selectOnBlur={false}
                                    className="filter-selection"
                                    options={visibilityOptions}
                                    value={filterData.visibility}
                                    onChange={(e, data) =>
                                        setFilterData({
                                            ...filterData,
                                            visibility: data.value as EVisibility,
                                        })
                                    }
                                />
                            </Table.HeaderCell>
                        )}
                        {!overview && (
                            <Table.HeaderCell>
                                <Select
                                    search
                                    selectOnBlur={false}
                                    className="filter-selection"
                                    options={
                                        resultsFilter?.countries.map<DropdownItemProps>(c => {
                                            return { key: c.id, value: c.id, text: c.name };
                                        }) || []
                                    }
                                    value={filterData.country}
                                    onChange={(e, data) =>
                                        setFilterData({
                                            ...filterData,
                                            country: data.value as string,
                                        })
                                    }
                                />
                            </Table.HeaderCell>
                        )}
                        {(!overview ||
                            user.role === UserRole.NCLP ||
                            user.role === UserRole.Viewer) && <Table.HeaderCell />}

                        {(overview ||
                            user.role === UserRole.NCLP ||
                            user.role === UserRole.Viewer) && <Table.HeaderCell />}

                        {!overview && user.role === UserRole.Admin && <Table.HeaderCell />}

                        <Table.HeaderCell>
                            <Button
                                primary
                                content="Clear filters"
                                onClick={() => {
                                    setFilterData(resultsFilterInit);
                                }}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Year</Table.HeaderCell>
                        <Table.HeaderCell>Category</Table.HeaderCell>
                        <Table.HeaderCell>Submission</Table.HeaderCell>

                        {!overview && <Table.HeaderCell>Status</Table.HeaderCell>}
                        {user.role === UserRole.Admin && (
                            <Table.HeaderCell>Visibility</Table.HeaderCell>
                        )}

                        {!overview && <Table.HeaderCell>Country</Table.HeaderCell>}

                        <Table.HeaderCell>Deadline</Table.HeaderCell>
                        {(!overview ||
                            user.role === UserRole.NCLP ||
                            user.role === UserRole.Viewer) && (
                            <Table.HeaderCell>Time of submission</Table.HeaderCell>
                        )}
                        {(overview ||
                            user.role === UserRole.NCLP ||
                            user.role === UserRole.Viewer) && <Table.HeaderCell />}
                        {!overview && user.role === UserRole.Admin && <Table.HeaderCell />}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {tableData?.map(element => {
                        let formStatus;

                        // Using the special deadline to calculate submission columns.
                        let specialDeadline = undefined;
                        if (user.role === UserRole.Admin && !overview) {
                            specialDeadline = element.specialDeadlines.find(
                                sd => sd.countryId === filterData.country
                            )?.deadline;
                        } else {
                            specialDeadline = element.specialDeadlines.find(
                                sd => sd.countryId === user.countryGuid
                            )?.deadline;
                        }

                        if (!overview) {
                            if (element.status === "Submitted") {
                                formStatus = "submitted";
                            } else if (element.status === "Partial") {
                                formStatus = "partially-filled";
                            } else {
                                formStatus = "new";
                            }

                            if (
                                new Date() > new Date(specialDeadline || element.deadline) &&
                                !element.isSubmitted
                            ) {
                                formStatus = "closed-not-submitted";
                            }
                        }

                        return (
                            <Table.Row key={element.formId}>
                                <Table.Cell
                                    className={`${!overview ? "clickable " : " "} ${formStatus}`}
                                    onClick={openForm.bind(null, element)}
                                >
                                    {element.formName}
                                </Table.Cell>
                                <Table.Cell>{element.formYear}</Table.Cell>
                                <Table.Cell>{element.categoryName}</Table.Cell>
                                <Table.Cell>
                                    {new Date(specialDeadline || element.deadline) < new Date()
                                        ? "Closed"
                                        : "Open"}
                                </Table.Cell>
                                {!overview && <Table.Cell>{element.status}</Table.Cell>}
                                {user.role === UserRole.Admin && (
                                    <Table.Cell>
                                        {displayVisibilityOption(element.visibility)}
                                    </Table.Cell>
                                )}
                                {!overview && <Table.Cell>{element.countryName}</Table.Cell>}
                                <Table.Cell>
                                    {new Date(specialDeadline || element.deadline).toLocaleString(
                                        "hr-HR",
                                        {
                                            day: "2-digit",
                                            month: "2-digit",
                                            year: "numeric",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                        }
                                    )}
                                    {specialDeadline ? " (Special)" : ""}
                                </Table.Cell>
                                {(!overview ||
                                    user.role === UserRole.NCLP ||
                                    user.role === UserRole.Viewer) && (
                                    <Table.Cell>
                                        {element.dateOfSubmission
                                            ? new Date(element.dateOfSubmission).toLocaleString(
                                                  "hr-HR",
                                                  {
                                                      day: "2-digit",
                                                      month: "2-digit",
                                                      year: "numeric",
                                                      hour: "2-digit",
                                                      minute: "2-digit",
                                                      second: "2-digit",
                                                  }
                                              )
                                            : ""}
                                    </Table.Cell>
                                )}
                                {((user.role === UserRole.Admin && overview) ||
                                    (user.role === UserRole.NCLP && !overview) ||
                                    (user.role === UserRole.Viewer && !overview)) && (
                                    <Table.Cell textAlign="right">
                                        {user.role === UserRole.Admin && overview && (
                                            <Popup
                                                content={`Unable to unpublish form. Form is started by${
                                                    element.countriesStarted.length > 5
                                                        ? ` ${element.countriesStarted.length} countries`
                                                        : `: ${element.countriesStarted.join(", ")}`
                                                }`}
                                                disabled={
                                                    !element.countriesStarted ||
                                                    element.countriesStarted.length === 0
                                                }
                                                trigger={
                                                    <span className="unpublish-button-holder">
                                                        <Button
                                                            primary
                                                            icon="eye slash"
                                                            disabled={
                                                                element.countriesStarted &&
                                                                element.countriesStarted.length > 0
                                                            }
                                                            onClick={() =>
                                                                setUnpublishModalForm(
                                                                    element.formId
                                                                )
                                                            }
                                                        />
                                                    </span>
                                                }
                                            />
                                        )}
                                        <Button
                                            primary
                                            icon="download"
                                            onClick={() => {
                                                setActiveXlsForm(element.formId);
                                                setActiveResultId(element.resultId);
                                            }}
                                        />

                                        {user.role === UserRole.Admin && (
                                            <>
                                                <Button
                                                    primary
                                                    onClick={() => {
                                                        history.push(
                                                            `/forms/overview/data-delivery/${element.formId}`
                                                        );
                                                    }}
                                                    className="data-delivery-button"
                                                >
                                                    <EuropeIcon />
                                                </Button>

                                                <Button
                                                    primary
                                                    icon
                                                    onClick={() => {
                                                        setActiveForm({
                                                            ...activeForm,
                                                            published: true,
                                                            id: element.formId,
                                                            name: element.formName,
                                                            description: element.formDescription,
                                                            category: element.categoryId,
                                                            deadline: element.deadline,
                                                            userDownload: element.userDownload,
                                                            sendNotification:
                                                                element.sendNotification,
                                                            specialDeadlines:
                                                                element.specialDeadlines,
                                                        });
                                                        setEditOpen(true);
                                                    }}
                                                >
                                                    <Icon name="setting" />
                                                </Button>
                                            </>
                                        )}
                                    </Table.Cell>
                                )}
                                {!overview && user.role === UserRole.Admin && (
                                    <Table.Cell className="right aligned">
                                        <Popup
                                            content="Export to PDF"
                                            trigger={
                                                <Button
                                                    primary
                                                    icon="download"
                                                    onClick={() => {
                                                        downloadPdf(element.resultId);
                                                    }}
                                                />
                                            }
                                        />
                                    </Table.Cell>
                                )}
                            </Table.Row>
                        );
                    })}
                </Table.Body>

                <CreateAndEditForm
                    header="Edit form"
                    cancelAddForm={() => setEditOpen(false)}
                    confirmString="Save"
                    createFormProcedure={editFormProcedure}
                    form={activeForm}
                    setForm={setActiveForm}
                    handleClose={() => setEditOpen(false)}
                    open={editOpen}
                    disableExceptDeadline
                />
            </Table>
            <Loader active={tableDataLoading} inline="centered" />

            <ExportModal
                formId={activeXlsForm}
                formName={tableData.find(f => f.formId === activeXlsForm)?.formName}
                categoryName={tableData.find(f => f.formId === activeXlsForm)?.categoryName}
                resultId={activeResultId}
                onClose={() => {
                    setActiveXlsForm(undefined);
                    setActiveResultId(undefined);
                }}
                downloadExcel={downloadExcel}
                dowloadPdf={downloadPdf}
            />

            <Modal
                open={unpublishModalForm !== undefined}
                onClose={() => setUnpublishModalForm(undefined)}
            >
                <Modal.Header>Unpublish confirmation</Modal.Header>
                <Modal.Content>
                    Are you sure you want to unpublish the form? Users will not be able to submit
                    their results until the form is published again.
                </Modal.Content>
                <Modal.Actions>
                    <Button content="Cancel" onClick={() => setUnpublishModalForm(undefined)} />
                    <Button primary content="Confirm unpublish" onClick={handleUnpublishForm} />
                </Modal.Actions>
            </Modal>
        </>
    );
};
