import { useCallback, useEffect, useState } from "react";
import { EVisibility, IForm } from "../../models/form";
import { Checkbox, Segment, Select, Table } from "semantic-ui-react";
import { getPublishedForms, updateFormVisibility } from "../../services/formsService";
import { toast } from "../..";
import { yearsProps } from "../../models/common";

export const FormsSection = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [publishedForms, setPublishedForms] = useState<IForm[]>([]);
    const [selectedYear, setSelectedYear] = useState<number>();

    const fetchPublishedForms = useCallback(async () => {
        try {
            setLoading(true);
            const res = await getPublishedForms(selectedYear);
            setPublishedForms(res);
        } catch (err) {
            toast("Unable to fetch forms", false, 3000);
        } finally {
            setLoading(false);
        }
    }, [selectedYear]);

    const updatePublicVisibilityForForm = useCallback(
        async (guid: string, visibility: EVisibility) => {
            try {
                await updateFormVisibility(guid, visibility);
                fetchPublishedForms();
                toast("Form visibility set successfully", true, 3000);
            } catch (err) {
                toast("Unable to edit visibility", false, 3000);
            }
        },
        [fetchPublishedForms]
    );

    useEffect(() => {
        fetchPublishedForms();
    }, [fetchPublishedForms]);

    return (
        <div className="forms-container section-container">
            <div className="title-container">
                <h2>Forms:</h2>
                <Select
                    clearable
                    value={selectedYear}
                    options={yearsProps}
                    onChange={(e, data) => setSelectedYear(data.value as number)}
                    selectOnBlur={false}
                />
            </div>

            <Segment>
                <Table basic="very" inline="centered" loading={loading}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Form Name</Table.HeaderCell>
                            <Table.HeaderCell>Year</Table.HeaderCell>
                            <Table.HeaderCell>Category</Table.HeaderCell>
                            <Table.HeaderCell>Submission</Table.HeaderCell>
                            <Table.HeaderCell>External publication</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {publishedForms.map(form => {
                            return (
                                <Table.Row key={form.id}>
                                    <Table.Cell>{form.name}</Table.Cell>
                                    <Table.Cell>
                                        {new Date(form.timePublished).getFullYear()}
                                    </Table.Cell>
                                    <Table.Cell>{form.categoryObj?.name}</Table.Cell>
                                    <Table.Cell>
                                        {new Date(form.deadline) < new Date() ? "Closed" : "Open"}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            checked={form.visibility === EVisibility.Public}
                                            onChange={(e, data) =>
                                                updatePublicVisibilityForForm(
                                                    form.id,
                                                    data.checked
                                                        ? EVisibility.Public
                                                        : EVisibility.Internal
                                                )
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </Segment>
        </div>
    );
};
