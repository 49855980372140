import { nullOrUndefined } from "../../helpers/commonHelper";
import { IQuestion, ISubQuestion } from "../../models/question";
import { IValue } from "../../models/value";

const isRequired = (question: IQuestion, value: IValue): boolean => {
    return (question.remark && !value.remarkText) || question.required;
};

const validateNumber = (question: IQuestion, value: IValue): boolean => {
    const hasValue = (value.numeric || value.numeric === 0) && !isNaN(value.numeric);

    if (hasValue) {
        if ((question.min || question.min === 0) && question.min > value.numeric!) {
            return false;
        }
        if ((question.max || question.max === 0) && question.max < value.numeric!) {
            return false;
        }
    } else {
        return !isRequired(question, value);
    }
    return true;
};

const validateShortText = (question: IQuestion, value: IValue): boolean => {
    if (value.textual) {
        if (question.min && question.min > value.textual.length) {
            return false;
        } else if (question.max && question.max < value.textual.length) {
            return false;
        }
    } else {
        return !isRequired(question, value);
    }
    return true;
};

// Function will return an array of all of [primary_id, secondary_id][] pair of values for subquestions
// for which the validation failed. Validation could fail because subvalue is missing or because it has
// an invalid value. For now just IsRequired is checked.
export const validateTableInput = (question: IQuestion, value: IValue): [string, string][] => {
    let subValuesWithValidationError: [string, string][] = [];

    let primarySubQ = question.subQuestions?.filter(sq => sq.isPrimaryDimension);
    let secondarySubQ = question.subQuestions?.filter(sq => !sq.isPrimaryDimension);

    secondarySubQ?.forEach(sq => {
        primarySubQ?.forEach(pq => {
            let subValue = value.subValues?.find(
                sv =>
                    sv.primaryDimensionSubQuestion === pq.id &&
                    sv.secondaryDimensionSubQuestion === sq.id
            );

            if (pq.isRequired || sq.isRequired) {
                if (
                    !subValue ||
                    (sq.subQuestionType === "Text" && !subValue.textValue) ||
                    (sq.subQuestionType === "Numeric" &&
                        (subValue.numeric === null ||
                            subValue.numeric === undefined ||
                            isNaN(subValue?.numeric)))
                ) {
                    // We did not find subvalue for given (primary, secondary) question pair or subvalue did not have value filled.
                    // Either way there is no suitable answer for given pair.
                    subValuesWithValidationError.push([pq.id, sq.id]);
                }
            }
        });
    });

    return subValuesWithValidationError;
};

export const validateQuestion = (question: IQuestion, value: IValue | undefined): boolean => {
    if (!value) {
        if (question.required || question.remark) {
            return false;
        }
    } else {
        if (question.type === "Number") {
            return validateNumber(question, value);
        } else if (question.type === "ShortText") {
            return validateShortText(question, value);
        } else if (question.type === "Paragraph") {
            return value.textual ? true : !isRequired(question, value);
        } else {
            return value.options && value.options.length > 0 ? true : !isRequired(question, value);
        }
    }
    return true;
};

// Returns an array of [question_id, primary_id, secondard_id] tuples for which the validation failed.
export const validateSpecialAcerPgmQuestion = (
    question: IQuestion,
    lastYearQuestion: IQuestion,
    value?: IValue,
    lastYearValue?: IValue
): [IQuestion, ISubQuestion, ISubQuestion, string, number][] => {
    // Sort the rows and cols
    let subValuesWithValidationError: [IQuestion, ISubQuestion, ISubQuestion, string, number][] =
        [];

    let rows = question.subQuestions?.filter(sq => sq.isPrimaryDimension);
    let cols = question.subQuestions?.filter(sq => !sq.isPrimaryDimension);

    let lastYearRows = lastYearQuestion.subQuestions?.filter(sq => sq.isPrimaryDimension);
    let lastYearCols = lastYearQuestion.subQuestions?.filter(sq => !sq.isPrimaryDimension);

    rows?.sort((a, b) => a.dimensionOrderNumber - b.dimensionOrderNumber);
    cols?.sort((a, b) => a.dimensionOrderNumber - b.dimensionOrderNumber);

    lastYearRows?.sort((a, b) => a.dimensionOrderNumber - b.dimensionOrderNumber);
    lastYearCols?.sort((a, b) => a.dimensionOrderNumber - b.dimensionOrderNumber);

    if (!rows || !cols || !lastYearRows || !lastYearCols) return subValuesWithValidationError;

    for (let i = 0; i < rows.length; i++) {
        // Go over all rows and first 9 columns

        for (let j = 0; j < 10; j++) {
            let subValueNumeric = value?.subValues?.find(
                sv =>
                    sv.primaryDimensionSubQuestion! === rows![i].id &&
                    sv.secondaryDimensionSubQuestion! === cols![j].id
            )?.numeric;
            let lastYearSubValueNumeric = lastYearValue?.subValues?.find(
                sv =>
                    sv.primaryDimensionSubQuestion === lastYearRows![i].id &&
                    sv.secondaryDimensionSubQuestion === lastYearCols![j].id
            )?.numeric;

            if (!nullOrUndefined(subValueNumeric) && !nullOrUndefined(lastYearSubValueNumeric)) {
                const delta = parseFloat((subValueNumeric! - lastYearSubValueNumeric!).toFixed(4));
                const preparedDeltaError: [IQuestion, ISubQuestion, ISubQuestion, string, number] =
                    [
                        question,
                        rows[i],
                        cols[j],
                        `Unexpected ${delta < 0 ? "negative" : "positive"} delta: ${delta}`,
                        delta,
                    ];
                if (delta < 0 && j < 9) {
                    subValuesWithValidationError.push(preparedDeltaError);
                }
                if (delta > 0 && ((j >= 3 && j <= 5) || j === 9)) {
                    subValuesWithValidationError.push(preparedDeltaError);
                }
            }
        }
    }

    return subValuesWithValidationError;
};

export const validateSpecialAcerHvdcQuestion = (
    question: IQuestion,
    lastYearQuestion: IQuestion,
    value?: IValue,
    lastYearValue?: IValue
): [IQuestion, ISubQuestion, ISubQuestion, string, number][] => {
    // Sort the rows and cols
    let subValuesWithValidationError: [IQuestion, ISubQuestion, ISubQuestion, string, number][] =
        [];

    let rows = question.subQuestions?.filter(sq => sq.isPrimaryDimension);
    let cols = question.subQuestions?.filter(sq => !sq.isPrimaryDimension);

    let lastYearRows = lastYearQuestion.subQuestions?.filter(sq => sq.isPrimaryDimension);
    let lastYearCols = lastYearQuestion.subQuestions?.filter(sq => !sq.isPrimaryDimension);

    rows?.sort((a, b) => a.dimensionOrderNumber - b.dimensionOrderNumber);
    cols?.sort((a, b) => a.dimensionOrderNumber - b.dimensionOrderNumber);

    lastYearRows?.sort((a, b) => a.dimensionOrderNumber - b.dimensionOrderNumber);
    lastYearCols?.sort((a, b) => a.dimensionOrderNumber - b.dimensionOrderNumber);

    if (!rows || !cols || !lastYearRows || !lastYearCols) return subValuesWithValidationError;

    for (let i = 0; i < rows.length; i++) {
        for (let j = 0; j < 4; j++) {
            let subValueNumeric = value?.subValues?.find(
                sv =>
                    sv.primaryDimensionSubQuestion === rows![i].id &&
                    sv.secondaryDimensionSubQuestion === cols![j].id
            )?.numeric;
            let lastYearSubValueNumeric = lastYearValue?.subValues?.find(
                sv =>
                    sv.primaryDimensionSubQuestion === lastYearRows![i].id &&
                    sv.secondaryDimensionSubQuestion === lastYearCols![j].id
            )?.numeric;

            if (!nullOrUndefined(subValueNumeric) && !nullOrUndefined(lastYearSubValueNumeric)) {
                const delta = parseFloat((subValueNumeric! - lastYearSubValueNumeric!).toFixed(4));
                const preparedDeltaError: [IQuestion, ISubQuestion, ISubQuestion, string, number] =
                    [
                        question,
                        rows[i],
                        cols[j],
                        `Unexpected ${delta < 0 ? "negative" : "positive"} delta: ${delta}`,
                        delta,
                    ];

                if (delta < 0 && j < 3) {
                    subValuesWithValidationError.push(preparedDeltaError);
                }
                if (delta > 0 && (j === 1 || j === 3)) {
                    subValuesWithValidationError.push(preparedDeltaError);
                }
            }
        }
    }

    return subValuesWithValidationError;
};
