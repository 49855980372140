import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal, Popup } from "semantic-ui-react";
import { toast } from "../..";
import { ImpLoading } from "../../components/ImpLoading/ImpLoading";
import { ICountryProps } from "../../models/country";
import { figureDtoInit, IFigureDto } from "../../models/figure";
import { getCountries } from "../../services/countriesService";
import { getFigureLabels, regenerateFigure } from "../../services/figuresService";
import { getBlocks } from "../../services/formsService";

interface IProps {
    regenerateModalOpen: boolean;
    figureToRegenerate: IFigureDto;
    setFigureToRegenerate: Dispatch<SetStateAction<IFigureDto>>;
    refreshFigures: () => void;
}

export const RegenerateModal = ({
    regenerateModalOpen,
    figureToRegenerate,
    setFigureToRegenerate,
    refreshFigures,
}: IProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [optionLabels, setOptionLabels] = useState<Record<string, string>>({});

    const [countriesToGenerate, setCountriesToGenerate] = useState<string[]>([]);
    const [countriesAsDropdownProps, setCountriesAsDropdownProps] = useState<ICountryProps[]>([]);

    const fetchCurrentOptions = useCallback(async () => {
        try {
            const res = await getFigureLabels(figureToRegenerate.id);
            setOptionLabels(res);
        } catch (err) {
            toast("Unable to fetch previous label values", false, 3000);
        }
    }, [figureToRegenerate.id]);

    useEffect(() => {
        if (figureToRegenerate.id !== "") {
            fetchCurrentOptions();
        }
    }, [fetchCurrentOptions, figureToRegenerate]);

    const handleRegenerateFigure = async () => {
        try {
            setLoading(true);
            await regenerateFigure({
                figureId: figureToRegenerate.id,
                countryGuids: countriesToGenerate,
                labels: optionLabels,
            });
            toast("Figure successfully regenerated", true, 3000);
            setFigureToRegenerate(figureDtoInit);
            setCountriesToGenerate([]);
            refreshFigures();
        } catch (error) {
            toast("Error regenerating figure", false, 3000);
        } finally {
            setLoading(false);
        }
    };

    const fetchCountriesAsDropdownProps = useCallback(async () => {
        try {
            const block = await getBlocks();
            const countries = await getCountries(
                block.find(x => x.name === "Network codes monitoring")?.id
            );
            if (Array.isArray(countries)) {
                const countriesAsProps: ICountryProps[] = [];
                countries.sort((a, b) => a.name.localeCompare(b.name));
                countries.forEach(country =>
                    countriesAsProps.push({
                        key: country.id,
                        text: country.name,
                        value: country.id,
                        eu: country.isEu ? 1 : 0,
                    })
                );

                const selectAll: ICountryProps = {
                    key: "selectAll",
                    text: "Select all",
                    value: "selectAll",
                    eu: 0,
                };
                const EU: ICountryProps = {
                    key: "EU",
                    text: "European Union",
                    value: "EU",
                    eu: 0,
                };
                countriesAsProps.unshift(EU);
                countriesAsProps.unshift(selectAll);
                setCountriesAsDropdownProps(countriesAsProps);
            }
        } catch (error) {
            toast("Error fetching countries", false, 1000);
        }
    }, []);

    const handleSelectCountriesToGenerate = (e: any, data: any) => {
        if (data.value.includes("selectAll")) {
            const allCountries: string[] = countriesAsDropdownProps
                .slice(2, countriesAsDropdownProps.length)
                .filter(item => item.value)
                .map(item => item.value!.toString());
            setCountriesToGenerate(allCountries);
        } else if (data.value.includes("EU")) {
            const euCountries: string[] = countriesAsDropdownProps
                .slice(2, countriesAsDropdownProps.length)
                .filter(item => item.eu === 1)
                .filter(item => item.value)
                .map(item => item.value!.toString());
            setCountriesToGenerate(euCountries);
        } else {
            setCountriesToGenerate(data.value);
        }
    };

    useEffect(() => {
        fetchCountriesAsDropdownProps();
    }, [fetchCountriesAsDropdownProps]);

    const handleCloseModal = () => {
        setCountriesToGenerate([]);
        setFigureToRegenerate(figureDtoInit);
    };

    return (
        <Modal open={regenerateModalOpen} className="regenerate-modal" onClose={handleCloseModal}>
            <ImpLoading show={loading} message="Regenerating figure, please wait..." />
            <Modal.Header>Request to regenerate: {figureToRegenerate.name}</Modal.Header>
            <Modal.Content>
                <div className="image-wrapper">
                    <img
                        src={`/api/figures/${figureToRegenerate.id}?thumbnail=false`}
                        alt={figureToRegenerate.name}
                    />
                </div>
                <Form>
                    <Form.Field className="regenerate-labels-wrapper">
                        {Object.keys(optionLabels).map(key => (
                            <div className="regenerate-input-wrapper">
                                <Form.Input
                                    key={key}
                                    value={optionLabels[key]}
                                    onChange={e =>
                                        setOptionLabels({
                                            ...optionLabels,
                                            [key]: e.target.value,
                                        })
                                    }
                                />
                                <span>Label for answer "{key}":</span>
                            </div>
                        ))}
                    </Form.Field>
                    <Form.Field>
                        <label>Countries which should be included in regenerated figure</label>
                        <Dropdown
                            fluid
                            multiple
                            search
                            selection
                            value={countriesToGenerate}
                            options={countriesAsDropdownProps}
                            onChange={(e, data) => handleSelectCountriesToGenerate(e, data)}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleCloseModal} content="Cancel" />
                <Popup
                    content="Please select at least one country"
                    disabled={countriesToGenerate.length > 0}
                    trigger={
                        <span>
                            <Button
                                primary
                                loading={loading}
                                disabled={countriesToGenerate.length === 0}
                                content="Regenerate"
                                onClick={handleRegenerateFigure}
                            />
                        </span>
                    }
                />
            </Modal.Actions>
        </Modal>
    );
};
