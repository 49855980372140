// Helper function that splits the text by \n and \t only if they are outside of double quotes
// That way splitting should still work properly even if copied content contains a new line by
// default.
// Answer found: https://stackoverflow.com/a/57105078
export const fullSplit = (text: string): string[][] | null => {
    let myRegExp = /(?:(\t)|(\r?\n)|"((?:[^"]+|"")*)"|([^\t\r\n]+))/gi;
    let match = myRegExp.exec(text);
    let emptyRow: string[] = [];
    let row = emptyRow.slice();
    let rows: string[][] = [];
    let prevTab = false;
    let prevRow = false;

    while (match !== null) {
        if (match[4]) {
            // Unquoted data.
            row.push(match[4]);
            prevTab = false;
            prevRow = false;
        } else if (match[3]) {
            // Quoted data (replace escaped double quotes with single)
            row.push(match[3].replace(/""/g, '"'));
            //row.push(match[3]);
            prevTab = false;
            prevTab = false;
        } else if (match[1]) {
            // Tab seperator
            if (prevTab) {
                // Two tabs means empty data
                row.push("");
            }
            if (prevRow) {
                // If first char after new line is a tab, then first cell should be empty
                row.push("");
            }
            prevTab = true;
            prevRow = false;
        } else if (match[2]) {
            // End of the row
            if (prevTab) {
                // Previously had a tab, so include the empty data
                row.push("");
            }
            prevTab = false;
            prevRow = true;
            rows.push(row);

            // Here we are ensuring the new empty row doesn't reference the old one.
            row = emptyRow.slice();
        }

        match = myRegExp.exec(text);
    }

    // Handles missing new line at end of string
    if (row.length) {
        if (prevTab) {
            // Previously had a tab, so include the empty data
            row.push("");
        }
        rows.push(row);
    }

    return rows;
};

// Inspired from: https://stackoverflow.com/a/61020180
export const sanitizeFloatText = (content: string): string => {
    if (!content) {
        return "";
    }

    //Index of first comma
    const posC = content.indexOf(",");

    if (posC === -1) {
        //No commas found, treat as float
        return content;
    }

    //Index of first full stop
    const posFS = content.indexOf(".");

    if (posFS === -1) {
        //Uses commas and not full stops - swap them (e.g. 1,23 --> 1.23)
        return content.replace(/,/g, ".");
    }

    //Uses both commas and full stops - ensure correct order and remove 1000s separators
    return posC < posFS ? content.replace(/,/g, "") : content.replace(/\./g, "").replace(",", ".");
};

// Client wants special handling for the first cell in the table if the form is "Generation Capacity" and the table is PGM table.
// Specifically, the first cell should contain the text which usually repeats in the row questions.
// That also means that in that specific case, the text in first cells of each row should be specially handled to not repeat the text from the first cell.
export const displayTableTopLeft = (questionText: string) => {
    // We do not have access to the form name here, so we are checking just the question name.
    // NOTE: Client changed the name of the question, so we are checking for the new name.
    if (questionText.toLowerCase().includes("collection of rfg data")) {
        return <span>Installed generation capacity [MW]</span>;
    }

    if (questionText.toLowerCase().includes("collection of hvdc data")) {
        return <span>Installed capacity [MW]</span>;
    }

    return <></>;
};

// Client wants special handling for the first cell in the table if the form is "Generation Capacity" and the table is PGM table.
// Specifically, the first cell should contain the text which usually repeats in the row questions.
// That also means that in that specific case, the text in first cells of each row should be specially handled to not repeat the text from the first cell.
export const displayFirstRowCell = (questionText: string, dimensionText: string) => {
    // We do not have access to the form name here, so we are checking just the question name.
    // NOTE: Client changed the name of the question, so we are checking for the new name.
    if (questionText.toLowerCase().includes("collection of rfg data")) {
        // Special logic that the client requested is that if the text contains "Type A", then only "Type A" should be displayed, and not the rest of the question text.
        // The same applies to "Type B", "Type C" and Type "D".
        if (dimensionText.toLowerCase().includes("type a")) {
            return <span>Type A</span>;
        }
        if (dimensionText.toLowerCase().includes("type b")) {
            return <span>Type B</span>;
        }
        if (dimensionText.toLowerCase().includes("type c")) {
            return <span>Type C</span>;
        }
        if (dimensionText.toLowerCase().includes("type d")) {
            return <span>Type D</span>;
        }
    }

    // Special handling of the hvdc table requested by the client.
    if (questionText.toLowerCase().includes("collection of hvdc data")) {
        let splitText = dimensionText.split("-");
        // If the last part of the text contains "Installed" and "[MW]" then remove only the last part and trim the combination of the rest.
        if (
            splitText.length > 1 &&
            splitText[splitText.length - 1].toLowerCase().includes("installed") &&
            splitText[splitText.length - 1].toLowerCase().includes("[mw]")
        ) {
            return (
                <span>
                    {splitText
                        .slice(0, splitText.length - 1)
                        .join("-")
                        .trim()}
                    {/** If the final part of the text contains the text "converter stations" then append the text " - converter stations" to the end */}
                    {splitText[splitText.length - 1]
                        .toLowerCase()
                        .includes("converter stations") ? (
                        <span> - converter stations</span>
                    ) : (
                        <></>
                    )}
                </span>
            );
        }
    }

    return <span>{dimensionText}</span>;
};
