import "semantic-ui-css/semantic.min.css";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import configureStore, { AppState } from "./store/configureStore";
import { Provider, useSelector } from "react-redux";
import { ForgotPassword } from "./containers/ForgotPassword/ForgotPassword";
import { SetToastMessage, ShowToastMessage, HideToastMessage } from "./actions/toastMessageActions";
import { Login } from "./containers/Login/Login";
import { ResetPassword } from "./containers/ResetPassword/ResetPassword";
import { PrivateRoute } from "./containers/Login/PrivateRoute";
import { FormView } from "./containers/FormView/FormView";
import { LocalLogin } from "./containers/Login/LocalLogin";
import { CountrySelect } from "./containers/CountrySelect/CountrySelect";
import { ToastMessage } from "./components/ToastMessage/ToastMessage";
import { App } from "./App";
import { Layout } from "./containers/Layout/Layout";
import { Home } from "./containers/Home/Home";
import { DataView } from "./containers/DataView/DataView";
import { PublicReportsOverview } from "./containers/PublicPages/Reports/PublicReportsOverview";
import { NonExhaustiveTabular } from "./containers/NonExhaustiveTabular/NonExhaustiveTabular";
import { FrtAdditionalChart } from "./containers/FrtChart/FrtAdditionalChart";
import { PublicFormResults } from "./containers/PublicPages/FormResults/PublicFormResults";

const store = configureStore();

export const toast = (text: string, success: boolean, timeout: number = 3000) => {
    const dispatch = store.dispatch;
    dispatch(SetToastMessage(text, success));
    dispatch(ShowToastMessage());
    setTimeout(() => dispatch(HideToastMessage()), timeout);
};

function ImpPublicView() {
    return (
        <Layout>
            <Switch>
                <Route exact path="/public/form-overview" component={PublicFormResults} />
                <Route exact path="/public/reports" component={PublicReportsOverview} />
                <Route
                    exact
                    path="/public/reports/non-exhaustive-parameters"
                    component={NonExhaustiveTabular}
                />
                <Route
                    exact
                    path="/public/reports/data-view/frt-additional"
                    component={FrtAdditionalChart}
                />
                <Route path="/public/reports/data-view/:chartType?" component={DataView} />
                <Route exact path="/public" component={Home} />
            </Switch>
        </Layout>
    );
}

// Wrapper added just to be able to update history to home page after admin changes it's view to NCLP.
function AppSwitchWrapper() {
    const sessionUser = sessionStorage.getItem("user");
    const history = useHistory();
    const setCurrUserRole = useState<string | undefined>(
        sessionUser ? JSON.parse(sessionUser).role : undefined
    )[1];
    const user = useSelector((state: AppState) => state.user);

    useEffect(() => {
        setCurrUserRole(oldRole => {
            if (oldRole !== user.role) {
                history.push("/");
                return user.role;
            }
        });
    }, [user, history, setCurrUserRole]);

    return (
        <Switch>
            <Route path="/public" component={ImpPublicView} />
            <Route exact={true} path="/login/admin" component={LocalLogin} />
            <Route exact={true} path="/login" component={Login} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/country-select" component={CountrySelect} />
            <Route path="/forms/view/:id/results/:resultId" component={FormView} />
            <PrivateRoute path="/forms/view/:id" component={FormView} />
            <PrivateRoute exact={true} path="/" component={App} />
            <PrivateRoute component={App} />
        </Switch>
    );
}

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <AppSwitchWrapper />
            <ToastMessage />
        </Router>
    </Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
