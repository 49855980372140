import { SyntheticEvent, ChangeEvent, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Accordion, Checkbox, Form, Icon, Input, Radio, TextArea } from "semantic-ui-react";
import { UserRole } from "../../actions/authentificationActions";
import generateGuid from "../../helpers/guid";
import { IFile } from "../../models/file";
import { IQuestion, ISubQuestion } from "../../models/question";
import { ISubValue, IValue } from "../../models/value";
import { AppState } from "../../store/configureStore";
import { TableInputDisplay } from "./TableInput/TableInputDisplay";
import "./QuestionDisplay.css";

interface IProps {
    question: IQuestion;
    value?: IValue;
    lastYearValue?: IValue;
    lastYearQuestion?: IQuestion;
    error?: boolean;
    subQuestionsErrors?: [string, string, string][];
    specialErrors?: [IQuestion, ISubQuestion, ISubQuestion, string, number][]; // Custom validation rules for ACER form
    disabled?: boolean;
    linked?: boolean;
    displayNumber?: number;
    saveValue?: (value: IValue, doSave: boolean) => void;
    manageFiles?: (value: IValue) => void;
}

function generateLimitationsText(question: IQuestion) {
    let result = "";
    result += question.min || question.min === 0 ? `min ${question.min}` : "";
    result +=
        (question.min || question.min === 0) && question.type !== "Number" ? ` characters` : "";
    result +=
        (question.min || question.min === 0) && (question.max || question.max === 0) ? `, ` : "";
    result += question.max || question.max === 0 ? `max ${question.max}` : "";
    result +=
        (question.max || question.max === 0) && question.type !== "Number" ? ` characters` : "";
    return result;
}

export const QuestionDisplay = ({
    question,
    value,
    lastYearValue,
    lastYearQuestion,
    error,
    subQuestionsErrors,
    specialErrors,
    disabled = false,
    linked = false,
    displayNumber,
    saveValue,
    manageFiles,
}: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [remarkOpen, setRemarkOpen] = useState<boolean | undefined>(
        (saveValue && Boolean(value?.remarkText)) as boolean
    );
    const [remarkVisible, setRemarkVisible] = useState<boolean | undefined>(
        (saveValue && value?.remarkText && question.remark) as boolean
    );

    useEffect(() => {
        setRemarkVisible(previous => {
            return (
                previous ||
                question.remark ||
                Boolean(value?.remarkText) ||
                specialErrors?.some(e => e[0].id === question.id)
            );
        });
    }, [
        setRemarkVisible,
        saveValue,
        question.remark,
        value?.remarkText,
        specialErrors,
        question.id,
    ]);

    const getValueOrNew = () => {
        if (value) return value;
        else {
            return {
                id: generateGuid(),
                question: question.id,
                files: [] as IFile[],
                subValues: [] as ISubValue[],
            } as IValue;
        }
    };

    const saveNumber = (val: IValue, e: ChangeEvent<HTMLInputElement>, doSave: boolean) => {
        saveValue!({ ...val, numeric: Number.parseFloat(e.target.value) }, doSave);
    };

    const saveText = (
        val: IValue,
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
        doSave: boolean
    ) => {
        saveValue!({ ...val, textual: e.target.value }, doSave);
    };

    const saveRadio = (id: string) => {
        if (saveValue) {
            let val = getValueOrNew();
            saveValue({ ...val, options: [id], textual: value?.textual }, true);
        }
    };

    const saveCheckboxes = (id: string) => {
        if (saveValue) {
            let val = getValueOrNew();
            let options = val.options ?? [];
            let newOptions;
            if (options.some(o => o === id)) {
                newOptions = options.filter(o => o !== id);
            } else {
                newOptions = [...options, id];
            }
            saveValue({ ...val, options: newOptions, textual: value?.textual }, true);
        }
    };

    const saveTableInput = (subValues: ISubValue[]) => {
        if (saveValue) {
            let val = getValueOrNew();
            val.subValues = subValues ?? [];
            // If all of the text in the answer is removed, as well as the numeric value,
            // then that subvalue is considered deleted.
            val.subValues = val.subValues.filter(
                sv => sv.textValue || (sv.numeric !== undefined && sv.numeric !== null)
            );

            saveValue({ ...val, subValues: val.subValues }, true);
        }
    };

    const saveChanges = (e: SyntheticEvent, doSave: boolean) => {
        let val = getValueOrNew();

        if (saveValue) {
            if (question.type === "Number") {
                saveNumber(val, e as ChangeEvent<HTMLInputElement>, doSave);
            } else if (question.type === "ShortText") {
                saveText(val, e as ChangeEvent<HTMLInputElement>, doSave);
            } else if (question.type === "MultipleChoice") {
                saveText(val, e as ChangeEvent<HTMLInputElement>, doSave);
            } else {
                saveText(val, e as ChangeEvent<HTMLTextAreaElement>, doSave);
            }
        }
    };

    const saveRemark = (e: SyntheticEvent, doSave: boolean) => {
        let val = getValueOrNew();

        if (saveValue) {
            const ev = e as ChangeEvent<HTMLTextAreaElement>;
            saveValue({ ...val, remarkText: ev.target.value }, doSave);
        }
    };

    return (
        <>
            <Form.Field
                className={
                    "title " + (question.required ? "required" : question.remark ? "remark" : "")
                }
            >
                {linked && <Icon name="chain" title="Linked to previous version" />}
                {displayNumber && <span className="number">{displayNumber}.</span>}
                {question.text}
                {(question.required || question.remark) && <span className="star"> *</span>}
                {question.fileUpload && (
                    <span
                        className="attach"
                        onClick={() => {
                            return manageFiles && manageFiles(getValueOrNew());
                        }}
                    >
                        {value && value.files?.length > 0 && `(${value.files.length}) `}
                        <Icon name="attach" title="File attachments" />
                    </span>
                )}
            </Form.Field>
            {question.imageGuid && (
                <div className="image-display-container">
                    <img
                        src={`/api/sections/questionImage/${question.imageGuid}`}
                        alt={question.text}
                    />
                </div>
            )}
            {/** If question is of type TableInput we will add our own custom error display. */}
            <Form.Field error={error && question.type !== "TableInput"}>
                {question.type === "TableInput" && (
                    <TableInputDisplay
                        question={question}
                        value={getValueOrNew()}
                        lastYearValue={lastYearValue}
                        lastYearQuestion={lastYearQuestion}
                        saveValues={saveTableInput}
                        errors={error ? subQuestionsErrors : []}
                        specialErrors={specialErrors}
                        disabled={disabled}
                    />
                )}
                {question.type === "ShortText" && (
                    <Input
                        type="text"
                        fluid
                        name={question.id}
                        value={value?.textual ?? ""}
                        input={{ minLength: question.min, maxLength: question.max }}
                        disabled={disabled}
                        onChange={(e: SyntheticEvent) => saveChanges(e, false)}
                        onBlur={(e: SyntheticEvent) => saveChanges(e, true)}
                    />
                )}
                {question.type === "Number" && (
                    <Input
                        type="number"
                        fluid
                        name={question.id}
                        value={value?.numeric ?? ""}
                        input={{ min: question.min, max: question.max }}
                        disabled={disabled}
                        onChange={(e: SyntheticEvent) => saveChanges(e, false)}
                        onBlur={(e: SyntheticEvent) => saveChanges(e, true)}
                    />
                )}
                {question.type === "Paragraph" && (
                    <TextArea
                        name={question.id}
                        value={value?.textual ?? ""}
                        disabled={disabled}
                        onChange={(e: SyntheticEvent) => saveChanges(e, false)}
                        onBlur={(e: SyntheticEvent) => saveChanges(e, true)}
                    />
                )}
                {question.type === "MultipleChoice" &&
                    question.options.map(o =>
                        o.value !== "Other" ? (
                            <Form.Field key={o.id}>
                                <Radio
                                    className={saveValue ? "" : "no-click"}
                                    name={question.id}
                                    checked={Boolean(value?.options && value.options[0] === o.id)}
                                    label={o.value}
                                    value={o.id}
                                    disabled={disabled}
                                    onChange={saveRadio.bind(null, o.id)}
                                />
                            </Form.Field>
                        ) : null
                    )}
                {question.type === "MultipleChoice" &&
                    question.options.map(o =>
                        o.value === "Other" ? (
                            <Form.Field key={o.id}>
                                <div className={"other-option-container"}>
                                    <Radio
                                        className={saveValue ? "" : "no-click"}
                                        name={question.id}
                                        checked={Boolean(
                                            value?.options && value.options[0] === o.id
                                        )}
                                        label={o.value}
                                        value={o.id}
                                        disabled={disabled}
                                        onChange={saveRadio.bind(null, o.id)}
                                    />
                                    <Input
                                        disabled={user.role === UserRole.Admin}
                                        type="text"
                                        fluid
                                        value={value?.textual ?? ""}
                                        input={{
                                            minLength: question.min,
                                            maxLength: question.max,
                                        }}
                                        onChange={(e: SyntheticEvent) => saveChanges(e, false)}
                                    />
                                </div>
                            </Form.Field>
                        ) : null
                    )}
                {question.type === "Checkboxes" &&
                    question.options.map(o =>
                        o.value !== "Other" ? (
                            <Form.Field key={o.id}>
                                <Checkbox
                                    className={saveValue ? "" : "no-click"}
                                    name={question.id}
                                    label={o.value}
                                    checked={value?.options && value.options.some(x => x === o.id)}
                                    value={o.id}
                                    disabled={disabled}
                                    onChange={saveCheckboxes.bind(null, o.id)}
                                />
                            </Form.Field>
                        ) : null
                    )}
                {question.type === "Checkboxes" &&
                    question.options.map(o =>
                        o.value === "Other" ? (
                            <Form.Field key={o.id}>
                                <div className={"other-option-container"}>
                                    <Checkbox
                                        className={saveValue ? "" : "no-click"}
                                        name={question.id}
                                        label={o.value}
                                        checked={
                                            value?.options && value.options.some(x => x === o.id)
                                        }
                                        value={o.id}
                                        disabled={disabled}
                                        onChange={saveCheckboxes.bind(null, o.id)}
                                    />
                                    <Input
                                        disabled={user.role === "Admin"}
                                        type="text"
                                        fluid
                                        value={value?.textual ?? ""}
                                        input={{
                                            minLength: question.min,
                                            maxLength: question.max,
                                        }}
                                        onChange={(e: SyntheticEvent) => saveChanges(e, false)}
                                    />
                                </div>
                            </Form.Field>
                        ) : null
                    )}
                {(question.type === "ShortText" || question.type === "Number") &&
                    (question.min || question.min === 0 || question.max || question.max === 0) && (
                        <div className="limitations">{generateLimitationsText(question)}</div>
                    )}
                {remarkVisible && (
                    <Accordion className="remark-content">
                        <Accordion.Title
                            active={remarkOpen}
                            onClick={setRemarkOpen.bind(null, saveValue && !remarkOpen)}
                        >
                            <Icon name="dropdown" />
                            Remark
                        </Accordion.Title>
                        <Accordion.Content active={remarkOpen}>
                            <TextArea
                                name={"remark-text"}
                                value={value?.remarkText}
                                disabled={!saveValue && disabled}
                                onChange={(e: SyntheticEvent) => saveRemark(e, false)}
                                onBlur={(e: SyntheticEvent) => saveRemark(e, true)}
                            />
                        </Accordion.Content>
                    </Accordion>
                )}
            </Form.Field>
        </>
    );
};
