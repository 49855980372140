import "./ExternalPublication.css";
import { FormsSection } from "./FormsSection";
import { GenerationCapacitiesSection } from "./GenerationCapacitiesSection";
import { NonExhaustiveSection } from "./NonExhaustiveSection";
import { ReportsSection } from "./ReportsSection";

export const ExternalPublication = () => {
    return (
        <div className="external-publications-container">
            <div className="container-header">
                <h1>External publication</h1>
            </div>
            <br />
            <ReportsSection />
            <NonExhaustiveSection />
            <GenerationCapacitiesSection />
            <FormsSection />
        </div>
    );
};
