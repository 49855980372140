import { ICategory } from "./category";
import { ISection } from "./section";
import { ISpecialDeadline } from "./specialDeadline";
import { ISendNotification, sendNotificationInit } from "./sendNotification";

export interface IForm {
    id: string;
    name: string;
    description: string;
    published: boolean;
    timeCreated: string;
    timeLastEdited: string;
    timePublished: string;
    deadline: string;
    category: string;
    categoryObj?: ICategory;
    parentId: string;
    parentTimePublished: string;
    sections: ISection[];
    isHardCopy: boolean;
    sameRootPublished: boolean;
    resultStatus?: "New" | "Partial";
    editModalDismissed?: boolean;
    specialDeadlines: Partial<ISpecialDeadline>[];
    userDownload: boolean;
    sendNotification: ISendNotification;
    visibility: EVisibility;
}

export enum EVisibility {
    Internal = "Internal",
    Public = "Public",
}

export const visibilityOptions = [
    { key: EVisibility.Internal, text: "Internal", value: EVisibility.Internal },
    { key: EVisibility.Public, text: "Public", value: EVisibility.Public },
];

export const displayVisibilityOption = (visibility: EVisibility) => {
    return visibilityOptions.find(x => x.key === visibility)?.text;
};

export const initNewForm: IForm = {
    id: "00000000-0000-0000-0000-000000000000",
    name: "",
    description: "",
    published: false,
    timeCreated: "",
    timeLastEdited: "",
    timePublished: "",
    deadline: "",
    category: "",
    parentId: "",
    parentTimePublished: "",
    sections: [],
    isHardCopy: false,
    sameRootPublished: false,
    specialDeadlines: [],
    userDownload: false,
    sendNotification: sendNotificationInit,
    visibility: EVisibility.Internal,
};
