import { useEffect, useState } from "react";
import { Modal, Checkbox, Button, Dropdown } from "semantic-ui-react";
import { ISection } from "../../models/section";
import { getSectionsOfForm } from "../../services/sectionsService";

import "./ExportModal.css";

const downloadOptions = [
    { key: "1", text: "Excel", value: "1" },
    { key: "2", text: "PDF", value: "2" },
];

interface IProps {
    formId?: string;
    formName?: string;
    categoryName?: string;
    resultId?: string;
    onClose: () => void;
    downloadExcel: (sectionId: string[], specialAcerDataFormat: boolean) => void;
    dowloadPdf: (resultId: string) => void;
}

export const ExportModal = ({
    formId,
    formName,
    categoryName,
    resultId,
    onClose,
    downloadExcel,
    dowloadPdf,
}: IProps) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [sections, setSections] = useState<ISection[]>([]);
    const [selectedSections, setSelectedSections] = useState<string[]>([]);
    const [allSectionsChecked, setAllSectionsChecked] = useState<boolean>(false);
    const [downloadType, setDownloadType] = useState<string>("");

    useEffect(() => {
        setModalOpen(false);
        if (formId) {
            getSectionsOfForm(formId).then(res => {
                setSections(res);
                setAllSectionsChecked(true);
                setSelectedSections(res.map(s => s.id));
                setModalOpen(true);
            });
        } else if (resultId) {
            setModalOpen(true);
        }
    }, [formId, resultId]);

    return (
        <Modal open={modalOpen} onClose={onClose}>
            <Modal.Header>Select data format</Modal.Header>
            <Modal.Content className="download-modal-content">
                <Dropdown
                    placeholder="Select file format for download"
                    selection
                    options={downloadOptions}
                    value={downloadType}
                    onChange={(_e, data) => {
                        setDownloadType(data.value as string);
                    }}
                    clearable
                />
                {downloadType === "1" && (
                    <>
                        <p>Select which sections should be involved in Excel file</p>
                        <Checkbox
                            label="Select all sections"
                            checked={allSectionsChecked}
                            onChange={() => {
                                // Select or unselect all sections.
                                setSelectedSections(
                                    allSectionsChecked ? [] : sections.map(s => s.id)
                                );
                                setAllSectionsChecked(!allSectionsChecked);
                            }}
                        />
                    </>
                )}
                <br />
                {sections.map(s => {
                    let index = selectedSections.indexOf(s.id);
                    return downloadType === "1" ? (
                        <Checkbox
                            key={s.id}
                            label={s.name}
                            checked={index !== -1}
                            onChange={() => {
                                let newSections = [...selectedSections];
                                if (index !== -1) {
                                    newSections = newSections.filter(guid => guid !== s.id);
                                } else {
                                    newSections.push(s.id);
                                }

                                setSelectedSections(newSections);
                            }}
                        />
                    ) : null;
                })}
            </Modal.Content>

            <Modal.Actions>
                <Button
                    content="Cancel"
                    onClick={() => {
                        setModalOpen(false);
                        setDownloadType("");
                        onClose();
                    }}
                />
                <Button
                    content={downloadType === "1" ? "Export to Excel" : "Export to PDF"}
                    icon="download"
                    onClick={() => {
                        setModalOpen(false);
                        downloadType === "1"
                            ? downloadExcel(selectedSections, false)
                            : dowloadPdf(resultId ?? "");
                        setDownloadType("");
                    }}
                    disabled={downloadType === ""}
                />
                {formName?.toLocaleLowerCase().includes("acer") && downloadType === "1" && (
                    <Button
                        content="ACER view format"
                        icon="download"
                        onClick={() => {
                            setModalOpen(false);
                            downloadType === "1"
                                ? downloadExcel(selectedSections, true)
                                : dowloadPdf(resultId ?? "");
                            setDownloadType("");
                        }}
                    />
                )}
            </Modal.Actions>
        </Modal>
    );
};
